@import "/src/styles/variables";
nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5em;
    .nav-top-bar {
        background-color: $color;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: .3em;
        width: calc(100% - 2.3em);
        padding-right: 2em;
        .header-icon-container{
            display: flex;
            gap: 20px;
            align-items: baseline;
            a{
                height: 20px;
            }
        }
        img {
            width: 1.2em;
        }
    }
    .nav-bottom-bar {
        display: flex;
        width: 100%;
        background-color: $white;
        border-bottom: 2px solid $color;
        padding: 1em 0;
        .mobile-elements-container{
            display: none;
            flex: 0 0 80%;
        }
        .logo-and-categories {
            flex: 0 0 20%;
            text-align: center;
            .logo img {
                margin-right: 1em;
                width: 100px;
                height: 100px;
            }
            .categories-container {
                position: relative;
                .category-button {
                    border: 1px solid $color;
                    background-color: $color;
                    border-bottom: 0;
                    display: inline-flex;
                    padding: 5px;
                    width: 200px;
                    justify-content: center;
                    align-items: center;
                    gap: .5em;
                    color: white;
                    cursor: pointer;
                    min-width: 125px;
                    p {
                        margin: 0;
                        font-size: 1em;
                    }
                    img {
                        width: 1.2em;
                        height: 1.2em;
                        align-self: center;
                        transform: translateY(2px);
                    }
                }
                .categories-extension {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%, 100%);
                    display: flex;
                    width: 210px;
                    flex-direction: column;
                    border: 1px solid $color;
                    border-top: 0;
                    background-color: $color;
                    .category {
                        color: white;
                        text-decoration: none;
                        padding: .5em;
                    }
                }
                .hide-categories {
                    display: none;
                }
            }
        }
    }
    .elements-container {
        flex: 0 0 65%;
        align-self: center;
        max-width: 1400px;
        .elements {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            flex-wrap: wrap;
            font-weight: 600;
            font-size: 1em;
            color: $color;
            gap: 1em;
            a {
                text-decoration: none;
            }
            .nav-heading {
                font-size: 2em;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .4em;
                video{
                    width: 100%;
                    
                }
                img {
                    height: 1.6em;
                }
            }
        }
    }
    .hamburger {
        cursor: pointer;
        display: none;
        margin: 20px;
        span {
            display: block;
            width: 3em;
            height: 4px;
            background: $color;
            margin-bottom: 5px;
        }
    }
    @media screen and (max-width: 950px) {
        .nav-bottom-bar{
            justify-content: space-between;
            #zac-logo{
                margin-left: 20px;
            }
        }
        .elements-container{
            display: none;
        }
        .category-button{
            display: none !important;
        }
        .elements {
            flex-direction: column;
            a {
                visibility: hidden;
                height: 0px;
                width: 0px;
            }
            .nav-heading{
                display: none;
            }
        }
        .logo>img {
            width: 5em;
        }
        .hamburger {
            display: unset !important;
        }
        &.mobileMenu {
            border-bottom: 3px solid $color;
            .logo {
                display: none;
            }
            
            .mobile-elements-container{
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-left: 20px;
                .category{
                    margin-left: 20px;
                }

            }
            .logo-and-categories{
                display: none;
            }
            .elements {
                a {
                    visibility: unset;
                    height: unset;
                    width: unset;
                }
                flex-wrap: unset;
                min-height: 10rem;
            }
        }
    }
}