@import "src/styles/_variables.scss";

footer {
  display: flex;
  justify-content: center;
  background: $color;
  color: white;

  ul {
    text-decoration: none;
    list-style: none;
    margin: 10px;
    a{
      text-decoration: none;
      color: white
    }
  }
  li {
    display: inline;
    margin-right: 15px;
  }
}
