@import "/src/styles/variables";
$icon-margin: 50px;

.service-section {
    p {
        color: $color;
        font-weight: 400;
        white-space: pre-line;
    }
    h2 {
        font-size: 2.5em;
        font-weight: 500;
        margin: 0;
    }

    width: 100%;
    max-width: 1000px;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 10rem;
    .pfeil {
        margin: 12rem auto;
        margin-top: 5rem;
    }
}

.icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: auto;
    transform: translateX(-$icon-margin);
    a {
        width: 230px;
        height: 190px;
        margin: $icon-margin;
        text-decoration: none;
    }
}
.icon-box {
    background-color: black;
}

.service-slider {
    padding-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        width: 30em;
        min-height: 10em;

        img {
            height: 6em;
            margin-bottom: 2em;
        }

        ul {
            text-align: left;
            list-style-type: circle;
            list-style-position: outside;
            max-width: 30em;

            li {
                margin-bottom: 1em;
            }
        }
    }

    .arrow-left,
    .arrow-right {
        cursor: pointer;
        user-select: none;
    }

    .arrow-left img {
        transform: rotate(90deg);
    }

    .arrow-right img {
        transform: rotate(-90deg);
    }
}

.service-slider-status {
    span {
        font-size: 2em;

        &.active {
            font-size: 2.5em;
        }
    }
}
