@import "/src/styles/variables";

.serive-page-container{
    max-width: $maxWidth;
    margin: 50px auto;
    min-height: 70vh;
    h1{
        margin-bottom: 50px;
    }
    .service-page-content-container{
        display: flex;
        flex-direction: column;
        gap: 50px;
        img{
            width: 150px;
            align-self: center;
        }
    }
    p{
        white-space: pre-line;
    }
}