@import "/src/styles/variables";

h3{
    font-size: 1.4rem;
    word-break: break-word;
}
.invisible{
    height: 0;
    visibility: hidden;
}
.break-element{
    display: block;
    text-align: left;
    word-break: break-word;
}

.job-posting{
    width: 80%;
    max-width: 800px;
    min-width: 300px;  
    margin: 50px auto;
    border-radius: 15px; 
    border: 2px solid #03036a;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    

    .img-container{
        background-image: url("https://images.pexels.com/photos/60877/pexels-photo-60877.jpeg?cs=srgb&dl=pexels-brian-60877.jpg&fm=jpg");
        width: 100%;
        height: 150px;
        background-position: center;
        background-size: cover;
    }
    .info-container{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 3%;
        /* gap: 20%; */
        *{
            text-align: left;
        }
        .headings{
            display: flex;
            flex-direction: column;
            flex-grow: 0.5;
        }
        .description{
            display: flex;
            flex-direction: column;
            .icon-description{
                display: flex;
                align-items: center;
                img{
                    height: 32px;
                    margin-right: 5px;
                }
            }
        }
    }
    .apply-info{
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       padding: 0px 3%;
       
       p{
           text-align: justify;
           font-size: 1.1rem;
           max-width: 100%;
       }
        .apply{
            margin: 40px 0;
            display: flex;
            justify-content: flex-end;
            padding: 5px 3%;
            .apply-btn{
                border: $border;
                padding: 15px 30px;
                border-radius: 5px;
                color: $color;
                font-weight: 600;
                font-size: 16px;
                background-color: transparent;
                cursor: pointer;
            }
        }
    }
    .expand-btn{
        
        padding: 5px 0;
        background-color: #EDEDED;
        display: flex;
        justify-content: center;
        cursor: pointer;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    /* p, h3{
        max-width: 75%;
    }
    .description{
        p{
            max-width: 70%;
        }
    } */
}