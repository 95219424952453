.iconbox{
    display: flex;
    flex-direction: column; 
    align-items: stretch;
    justify-content: center;
    margin: 20px; 
    width: 100%;
    img{
        width: 80%;
        margin-bottom: 10px; 
        margin: 0 auto;
    } 
    span{
        text-align: center;
        font-size: 90%;
        font-weight: 700;
    }
}