@import "/src/styles/variables";

.sidebar{
    position: fixed;
    top: 50vh;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    img{
        margin: 5px 0;
        cursor: pointer;
        width: 75px;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
    .contact-details-container{
        position: absolute;
        width: 350px;
        border: 1px solid $color;
        right: 100px;
        top: -20vh;
        padding: 20px;
        border-radius: 5px;
        background-color: white;
        transition: .5s;
        p{
            text-align: left;
            transition-delay: .5s;
        }
    }
}
