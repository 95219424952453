@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
@import "/src/styles/variables";

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0px;
  background: $background;
  font-family: $font;
  color: $color;
}


.flex {
    display: flex;
    justify-content: center;
  }
  main {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;  
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
  }
  .start-section {

    margin-bottom: 50px;
    color: $color;
    
    scroll-behavior: smooth; 
    p{
      max-width: 1000px;
      display: block;
      white-space: pre-line;
      margin: 20px auto;
    }
  }
  .section-arrow{
    align-self: center;
  }
  .grafik{
    display: block;
    margin: 0 auto;
    width: 50%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  p {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    &.alpha {
      color: rgba($color: $color, $alpha: 1);
    }
  }
  span {
    color: rgba($color: $color, $alpha: 1);
  }

