@import "/src/styles/variables";
$icon-margin: 50px;

.unternehmen-section{
    p{
        font-weight: 400;
        color: $color;
        white-space: pre-line; 
    }
    h2{
        font-size: 2.5em;
        font-weight: 500;
        margin: 0;
    }

    width: 100%; 
    max-width: 1200px;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 10rem;
    .pfeil{
        margin: 12rem auto;
        margin-top: 5rem;
    }
}

.partner-container{
    padding: 5em 0;
    display: flex;
    gap: 5em;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: auto;

    img {
        flex: 1 1 0px;
    }
} 

.jobsImg {
    margin-bottom: 2em;
}