.thankYou {
    margin-top: 10em;
    display: flex;
    justify-content: center;
    min-height: 60vh;

    a {
        text-decoration: none;
    }

    h4 {
        text-align: center;
    }
}