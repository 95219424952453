.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}
h1{
    color: #03036A;
    text-align: center;
    font-weight: 400;
}
.input-container{
    border: 2px solid #03036A;
    border-radius: 5px;
    display: flex;
    width: 500px;
    margin: 20px 0;
    min-height: 40px;
}
.input-container label{
    flex: 0 1 30%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    align-self: center;
}
.input-container input{
    outline: none;
    flex: 1 0 70%;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: .5px solid #03036A;
    font-size: 16px;
    padding-left: 10px;
}

@media screen and (max-width: 600px) {
    .input-container{
        width: 100%;
        flex-wrap: wrap;

        input {
            border-left: none;
            width: 100%;
            min-height: 2em;
            border-top: 2px solid #03036A;;
            border-radius: 0px;
        }

        label {
            text-align: left;
            padding: 0.5em;
        }
    }
}

.login-btns{
    display: flex;
    justify-content: space-evenly;
}
.login-btns input{
    width: 150px;
    height: 50px;
    border: 2px solid #03036A;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}
.login-btns .send{
    background-color: #03036A;
    color: white;
}
.login-btns .reset{
    color: #03036A
}